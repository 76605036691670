import { Button, Flex, MediaQuery, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { handleKeyDown } from "utils/event";

export interface SearchProps {
    search: string;
    searchFields: string[];
    pageSize: string;
}

interface SetSearchProps {
    searchType: { label: string; value: string }[];
    setSearch: any;
}

// const SearchPageSizes = [
//     { label: "5개씩", value: "5" },
//     { label: "10개씩", value: "10" },
//     { label: "20개씩", value: "20" },
//     { label: "50개씩", value: "50" },
//     { label: "100개씩", value: "100" }
// ];

const SearchBox = ({ searchType, setSearch }: SetSearchProps) => {
    const form = useForm({
        initialValues: {
            searchFields: "",
            search: "",
            pageSize: "10"
        },
    });

    const handleSearch = () => {
        setSearch((prev: any) => ({
            ...prev,
            search: form.values.search,
            searchFields: form.values.searchFields && form.values.searchFields !== "" ? [form.values.searchFields] : prev.searchFields,
        }));
    };

    return (
        <>
            <MediaQuery styles={{ display: "none" }} smallerThan="sm">
                <Flex gap="md" justify="center" align="center">
                    {/* <Select data={SearchPageSizes} placeholder="검색"  {...form.getInputProps("pageSize")} w="20%" /> */}
                    <Select data={searchType} placeholder="통합 검색"  {...form.getInputProps("searchFields")} />
                    <TextInput placeholder="검색어를 입력하세요" {...form.getInputProps("search")} w="100%" onKeyDown={(e) => handleKeyDown(e, handleSearch)} />
                    <Button onClick={handleSearch}>검색</Button>
                </Flex>
            </MediaQuery>
            <MediaQuery styles={{ display: "none" }} largerThan="sm">
                <Flex gap="md" justify="center" align="center" direction="column">
                    {/* <Select data={SearchPageSizes} placeholder="검색"  {...form.getInputProps("pageSize")} w="20%" /> */}
                    <Select data={searchType} placeholder="통합 검색"  {...form.getInputProps("searchFields")} w="100%" />
                    <TextInput placeholder="검색어를 입력하세요" {...form.getInputProps("search")} w="100%" onKeyDown={(e) => handleKeyDown(e, handleSearch)} />
                    <Button onClick={handleSearch} w="100%">검색</Button>
                </Flex>
            </MediaQuery>
        </>
    )
}

export default SearchBox;