import { MantineProvider } from "@mantine/core";
import ModalStackManager from "components/Modal/ModalStackManager";

import QueryProvider from "provider/QueryProvider";
import ThemeProvider from "provider/ThemeProvider";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import GlobalStyle from "style/Global";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <RecoilRoot>
    <QueryProvider>
      <ThemeProvider>
        <MantineProvider withGlobalStyles withNormalizeCSS>
          <ModalStackManager>
            <GlobalStyle />
            <App />
          </ModalStackManager>
        </MantineProvider>
      </ThemeProvider>
    </QueryProvider>
  </RecoilRoot>
);
