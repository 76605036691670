import axios, { AxiosError } from "axios";
import { useModal } from "components/Modal/ModalStackManager";
import { authEnum } from "config/auth";
import { FileNameLength, FileSize } from "constants/fileInfo";
import { BASE_URL } from "instance/axios";
import { AddedFileProps, FileModalForm } from "./FileModalForm";

const FileForm = ({
  ticketId,
}: {
  ticketId: number;
}) => {
  const { closeModal } = useModal();
  const onSave = async (files: AddedFileProps[]) => {
    const formData = new FormData();

    Array.from(files).forEach((file: any) => {
      formData.append("file", file);
    });

    // 파일 이름 길이 체크
    const isFileNameLengthOver = files.every((file) => file.name.length < FileNameLength);
    // 파일 사이즈 체크
    const isFileSizeOver = files.every((file) => file.size < FileSize);

    if (isFileNameLengthOver && isFileSizeOver) {
      await axios({
        method: "post",
        url: `${BASE_URL}/ticket/${ticketId}/attachment`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(authEnum.authToken)}`,
        },
      })
        .then((result: any) => {
          if (result.status === 200) {
            alert("업로드 되었습니다.");
            closeModal({});
          }
        })
        .catch((err: AxiosError) => {
          alert("파일 업로드가 불가합니다."); // 파일 용량이 초과되었습니다.
          console.log(err);
        });
    } else {
      alert("파일 업로드가 불가합니다."); // 파일 용량이 초과되었습니다.
    }
  };

  return (
    <FileModalForm ticketId={ticketId} onSave={onSave} />
  );
};

export default FileForm;
