import { Button, Flex, Text } from "@mantine/core";
import { useModal } from "components/Modal/ModalStackManager";


interface ConfirmProps {
    usesNotification: boolean
}


const Confirm = ({ usesNotification }: ConfirmProps) => {
    const { closeModal } = useModal();

    return (
        <Flex direction="column" align="center" gap="xl">
            <Flex direction="column" align="center" gap="xs" w="100%">
                <Text>정비를 시작하시겠습니까?</Text>
                {
                    usesNotification &&
                    <>
                        <Text>정비 시작을 누르시면,</Text>
                        <Text>차주에게 알림톡이 발송됩니다.</Text>
                    </>
                }
            </Flex>
            <Flex justify="space-between" w="100%" gap="md">
                <Button w="50%" color="gray" onClick={() => closeModal(false)}>취소</Button>
                <Button w="50%" onClick={() => closeModal(true)}>정비시작</Button>
            </Flex>
        </Flex>
    )
}

export default Confirm;