import styled from "@emotion/styled";
import { Title } from "@mantine/core";
import useScanQR from "hook/useScanQR";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TicketTable } from "./components/TicketTable";

const QR = () => {
    const { qrData, setQrData } = useScanQR();
    const navigate = useNavigate();

    useEffect(() => {
        if (qrData) {
            const path = new URL(qrData).pathname;
            navigate(path);
        }
    }, [qrData]);

    return (
        <QRContainer>
            <QRBox>
                <Title order={2}>QR 코드 인식</Title>
                <QRReaderBox id="qr-reader" />
            </QRBox>
            <TicketTable />
        </QRContainer>

    );

}

const QRContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;
`

const QRBox = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50px;
    gap: 2rem;
    padding: 2rem;
    border-radius: 4rem;

    @media (max-width: 768px) {
        padding : 0.5rem;
        gap : 1rem;
        border-radius: 2rem;
    }
`

const QRReaderBox = styled.div`
  width: 50%;
  height: 50%;
  overflow: hidden;
  & video {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;

    #qr-shaded-region {
        border-width: 36px 40px !important;
    }
  }
`;

export default QR;