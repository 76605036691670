import { atom } from "recoil";

export interface UserInfo {
  workBayName: string;
  centerName: string;
  managerName: string;
}

export const initialUserInfo: UserInfo = {
  workBayName: "",
  centerName: "",
  managerName: "",
};

export const userInfoAtom = atom<UserInfo>({
  key: "userInfoAtom",
  default: {
    workBayName: "",
    centerName: "",
    managerName: "",
  },
});
