import { css, keyframes } from "@emotion/react";
import { MantineThemeOverride } from "@mantine/core";

export const colors = {
  black: "rgb(17 24 39)",
  darkNavy: "#161B21",
  white: "#EEF0F4",
  indigo: "#1B273B",
  grayOne: "#F7F7F7",
  grayTwo: "#E5E5E5",
  grayThree: "#707070",
  grayFour: "#5a5a5a",
  grayFive: "#9b9a97",
  graySix: "#586a84",
  pointColorBlack: "#161B21",
  pointColorGray: "#3B4758",
  pointColorPurple: "#B142F5",
  pointColorYellow: "#ffc114",
  pointColorCarrot: "#ff5248",
  pointColorBrown: "#E1AD49",
  pointColorMint: "#19cdca",
  pointColorLightblue: "#319dff",
  pointColorBlue: "#0077E4",
  pointColorPastelBlue: "#4165c8bf",
  pointColorGreen: "#0DA678",
  pointColorGrapefruit: "#d55b10bf",
  pointColorBlueGrey: "#162d6cbf",
  lightblue: "#C5E2EE",
  footerColor: "#292929",
  mainColor: "#E7286A",
  waringColor: "#ff3838",
  starColor: "#fd4",
  beige: "#f6d77d",
  beigeRed: "#ffd4c9",
  grayGradient_100:
    "linear-gradient(180deg, rgba(211.69, 237.34, 245.44, 0.38) 0%, rgba(60.03, 87.02, 127.50, 0.23) 100%)",
} as const;

export const gradients = {
  pointGraidentBlue: css`
    background: linear-gradient(
      to right,
      ${colors.pointColorBlue},
      ${colors.pointColorMint}
    );
  `,
} as const;

//cetering
export const middle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const centerRow = css`
  ${middle}
  justify-content: initial;
`;

export const centerCol = css`
  ${middle}
  flex-direction: column;
  justify-content: initial;
`;

export const deviceSize = {
  mobileM: "500px",
  tablet: "765px",
};

//! animations
export const animations = {
  rotate: keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
  `,
  fadeIn: keyframes`
    0%{
      opacity:0;
      transform: translateY(3rem);
    }
    100%{
      opacity:1;
      transform: translateY(0rem);
    }
  `,
  fadeInOut: keyframes`
       0%  {opacity: 0}
       20% {opacity: 0}
       50% {opacity: 1}
       100%{opacity: 0}
  `,
  popup: keyframes`
    0% {
      opacity:0; 
      transform:scale(0);
    }
    30%{
      opacity:1;
      transform: scale(1);
    }
    40%{
      transform: scale(0.85);
    }
    60%{
      transform: scale(1.05);
    }
    80%{
      transform: scale(1);
    }
    100%{
      transform: scale(1);
    }
  `,
};

export type ModeType = "white" | "dark";

export const themeMode = (mode: ModeType) => {
  const commonCSS = {
    middle,
    centerRow,
    centerCol,
    animations,
  };

  const modeCSS = {
    backgroundColor:
      "linear-gradient(180deg, black 0%, #1B273B 67%, rgba(116.56, 126.58, 136.19, 0.51) 90%, rgba(225.83, 231.63, 230.90, 0) 100%)",
    fontColor: mode === "white" ? colors.black : colors.white,
    pointColor:
      mode === "white" ? colors.pointColorBlue : colors.pointColorYellow,
    subPointColor: mode === "white" ? colors.pointColorGray : colors.beige,
    linkColor:
      mode === "white" ? colors.pointColorPastelBlue : colors.pointColorMint,
  } as const;

  return {
    ...commonCSS,
    ...modeCSS,
  };
};

export type ThemeType = ReturnType<typeof themeMode>;

export const theme: MantineThemeOverride = {
  colors: {
    dark: [
      "#C1C2C5",
      "#A6A7AB",
      "#909296",
      "#5c5f66",
      "#373A40",
      "#2C2E33",
      "#25262b",
      "#1A1B1E",
      "#141517",
      "#101113",
    ],
    gray: [
      "#f8f9fa",
      "#f1f3f5",
      "#e9ecef",
      "#dee2e6",
      "#ced4da",
      "#adb5bd",
      "#868e96",
      "#495057",
      "#343a40",
      "#212529",
    ],
    red: [
      "#fff5f5",
      "#ffe3e3",
      "#ffc9c9",
      "#ffa8a8",
      "#ff8787",
      "#ff6b6b",
      "#fa5252",
      "#f03e3e",
      "#e03131",
      "#c92a2a",
    ],
    pink: [
      "#fff0f6",
      "#ffdeeb",
      "#fcc2d7",
      "#faa2c1",
      "#f783ac",
      "#f06595",
      "#e64980",
      "#d6336c",
      "#c2255c",
      "#a61e4d",
    ],
    grape: [
      "#f8f0fc",
      "#f3d9fa",
      "#eebefa",
      "#e599f7",
      "#da77f2",
      "#cc5de8",
      "#be4bdb",
      "#ae3ec9",
      "#9c36b5",
      "#862e9c",
    ],
    violet: [
      "#f3f0ff",
      "#e5dbff",
      "#d0bfff",
      "#b197fc",
      "#9775fa",
      "#845ef7",
      "#7950f2",
      "#7048e8",
      "#6741d9",
      "#5f3dc4",
    ],
    indigo: [
      "#edf2ff",
      "#dbe4ff",
      "#bac8ff",
      "#91a7ff",
      "#748ffc",
      "#5c7cfa",
      "#4c6ef5",
      "#4263eb",
      "#3b5bdb",
      "#364fc7",
    ],
    blue: [
      "#e7f5ff",
      "#d0ebff",
      "#a5d8ff",
      "#74c0fc",
      "#4dabf7",
      "#339af0",
      "#228be6",
      "#1c7ed6",
      "#1971c2",
      "#1864ab",
    ],
    cyan: [
      "#e3fafc",
      "#c5f6fa",
      "#99e9f2",
      "#66d9e8",
      "#3bc9db",
      "#22b8cf",
      "#15aabf",
      "#1098ad",
      "#0c8599",
      "#0b7285",
    ],
    teal: [
      "#e6fcf5",
      "#c3fae8",
      "#96f2d7",
      "#63e6be",
      "#38d9a9",
      "#20c997",
      "#12b886",
      "#0ca678",
      "#099268",
      "#087f5b",
    ],
    green: [
      "#ebfbee",
      "#d3f9d8",
      "#b2f2bb",
      "#8ce99a",
      "#69db7c",
      "#51cf66",
      "#40c057",
      "#37b24d",
      "#2f9e44",
      "#2b8a3e",
    ],
    lime: [
      "#f4fce3",
      "#e9fac8",
      "#d8f5a2",
      "#c0eb75",
      "#a9e34b",
      "#94d82d",
      "#82c91e",
      "#74b816",
      "#66a80f",
      "#5c940d",
    ],
    yellow: [
      "#fff9db",
      "#fff3bf",
      "#ffec99",
      "#ffe066",
      "#ffd43b",
      "#fcc419",
      "#fab005",
      "#f59f00",
      "#f08c00",
      "#e67700",
    ],
    orange: [
      "#fff4e6",
      "#ffe8cc",
      "#ffd8a8",
      "#ffc078",
      "#ffa94d",
      "#ff922b",
      "#fd7e14",
      "#f76707",
      "#e8590c",
      "#d9480f",
    ],
    white: [
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
    ],
  },
  fontSizes: {
    xl: "24px",
    lg: "20px",
    md: "16px",
    sm: "12px",
    xs: "8px",
  },
  fontFamily: 'MBK CorpoS',
  cursorType: "pointer",
};
