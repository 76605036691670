import {
  Configuration,
  DefaultApi,
  MasterApi,
  TicketApi,
  TicketUserViewApi,
} from "@sizlcorp/mbk-api-document/dist/models";
import axios from "axios";
export const BASE_URL =
  process.env.REACT_APP_BASE_URL ?? "https://mbk-staging.sizl.co.kr/api";

export const axiosInstance: any = axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 60 * 60 * 24 * 7,
});

// Add an interceptor to the axios instance
axiosInstance.interceptors.request.use((req: any) => {
  const token = localStorage.getItem("authToken");
  req.headers["Authorization"] = "Bearer " + token;
  return req;
});
export const baseAxios = (url?: string) => {
  return new DefaultApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
};
export const masterAxios = (url?: string) => {
  return new MasterApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
};
export const ticketAxios = (url?: string) => {
  return new TicketApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
};

export const ticketUserAxios = (url?: string) => {
  return new TicketUserViewApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  )
}

const BaseInstance = baseAxios(BASE_URL);
export const MasterInstance = masterAxios(BASE_URL);
export const TicketInstance = ticketAxios(BASE_URL);
export const TicketUserViewInstance = ticketUserAxios(BASE_URL);
export default BaseInstance;