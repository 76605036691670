import styled from "@emotion/styled";
import { Button, Checkbox, Flex, Select } from "@mantine/core";
import { PendingReasonResponse } from "@sizlcorp/mbk-api-document/dist/models";
import { usePendingReasonQuery } from "api/useMasterQuery";
import { useModal } from "components/Modal/ModalStackManager";
import { StepStatusDataPending, StepStatusDataPendingKeys } from "constants/stepStatus";
import { useRef, useState } from "react";

interface PendingReasonsProps {
    siteId: number;
    pendingType: StepStatusDataPendingKeys;
    usesNotification: boolean
}

const PendingReasons = ({ siteId, pendingType, usesNotification }: PendingReasonsProps) => {
    const { closeModal } = useModal();
    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState<string>("");
    const message = useRef<HTMLInputElement>(null);
    const { data } = usePendingReasonQuery(siteId, StepStatusDataPending[pendingType]);

    const pendingReasons = data?.map((reason: PendingReasonResponse) => {
        return {
            value: reason.id.toString(),
            label: reason.name,
        }
    });

    return (
        <PendingReasonContent w="30rem" h="auto" direction="column" gap="sm">
            <Checkbox
                disabled={!usesNotification}
                label="알림톡 사용"
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
            />
            <Select data={pendingReasons ?? []} withinPortal size="lg" onChange={(e: string) => setValue(e)} />
            {/* <TextInput label="상세 내용" placeholder="지연 상세 내용을 입력하세요." ref={message} /> */}
            <Button onClick={() => closeModal({ pendingReasonId: value, message: message.current?.value, usePendingNotification: checked })} disabled={!value.length}>저장</Button>
        </PendingReasonContent>
    )
}

export default PendingReasons;

const PendingReasonContent = styled(Flex)`
    @media (max-width: 768px) {
        width: 100%;
    }
`