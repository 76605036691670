import { useMutation, useQueryClient } from "react-query";
import { QUERY_KEY as repairQueryKey } from "./useRepairQuery";
// import BaseInstance from "instance/axios";
import { TicketApiTicketsTicketIdCancelFinishAutoPutRequest, TicketApiTicketsTicketIdFinishAutoPutRequest, TicketApiTicketsTicketIdFinishProcessPutRequest, TicketApiTicketsTicketIdPendAutoPutRequest, TicketApiTicketsTicketIdPutRequest, TicketApiTicketsTicketIdResumeAutoPutRequest, TicketApiTicketsTicketIdStartAutoPutRequest, TicketApiTicketsTicketIdStartProcessPutRequest, TicketApiTicketsTicketIdUpdateParkingLocationPutRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { TicketInstance } from "instance/axios";

type StartParams = TicketApiTicketsTicketIdStartAutoPutRequest;
type PendingParams = TicketApiTicketsTicketIdPendAutoPutRequest;
type ResumeParams = TicketApiTicketsTicketIdResumeAutoPutRequest;
type StartProcessParams = TicketApiTicketsTicketIdStartProcessPutRequest;
type FinishProcessParams = TicketApiTicketsTicketIdFinishProcessPutRequest;
type FinishParams = TicketApiTicketsTicketIdFinishAutoPutRequest;
type SaveParams = TicketApiTicketsTicketIdUpdateParkingLocationPutRequest;
type RestartParams = TicketApiTicketsTicketIdCancelFinishAutoPutRequest;
type UpdateParams = TicketApiTicketsTicketIdPutRequest;

const REPAIR_ALERT_MESSAGE = {
  start: "정비가 시작되었습니다.",
  pending: "정비가 지연되었습니다.",
  resume: "정비가 재개되었습니다.",
  finish: "정비가 완료되었습니다.",
  save: "주차 위치가 저장되었습니다.",
  process: "상세 정비가 시작되었습니다.",
  restart: "정비가 재시작되었습니다.",
};


const useRepairMutation = () => {
  const queryClient = useQueryClient();

  // 정비 시작
  const startMutation = useMutation(
    (params: StartParams) => {
      return TicketInstance.ticketsTicketIdStartAutoPut({ ticketId: params.ticketId });
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.start);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  );

  // 정비 지연
  const pendingMutation = useMutation(
    (params: PendingParams) => {
      return TicketInstance.ticketsTicketIdPendAutoPut(params);
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.pending);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  );

  // 정비 재개
  const resumeMutation = useMutation(
    (params: ResumeParams) => {
      return TicketInstance.ticketsTicketIdResumeAutoPut(params);
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.resume);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  );

  // 상세 정비 시작
  const startProcessMutation = useMutation(
    (params: StartProcessParams) => {
      return TicketInstance.ticketsTicketIdStartProcessPut(params);
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.process);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  );

  // 상세 정비 종료 (완료)
  const finishProcessMutation = useMutation(
    (params: FinishParams) => {
      return TicketInstance.ticketsTicketIdFinishProcessPut({ ticketId: params.ticketId });
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.finish);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  );

  // 정비 종료
  const finishMutation = useMutation(
    (params: FinishProcessParams) => {
      return TicketInstance.ticketsTicketIdFinishAutoPut({ ticketId: params.ticketId });
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.finish);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  );

  const restartMutation = useMutation(
    (params: RestartParams) => {
      return TicketInstance.ticketsTicketIdCancelFinishAutoPut({ ticketId: params.ticketId, ticketsTicketIdCancelFinishAutoPutRequest: {} });
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.restart);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  )

  // 주차 위치 업데이트
  const saveParkingLocationMutation = useMutation(
    (params: SaveParams) => {
      return TicketInstance.ticketsTicketIdUpdateParkingLocationPut({
        ticketId: params.ticketId,
        ticketsTicketIdUpdateParkingLocationPutRequest: {
          parkingLocation: params.ticketsTicketIdUpdateParkingLocationPutRequest.parkingLocation
        }
      });
    },
    {
      onSuccess: () => {
        alert(REPAIR_ALERT_MESSAGE.save);
        queryClient.invalidateQueries(repairQueryKey);
      }
    }
  );

  // 접수건 수정
  const updateMutation = useMutation(
    (params: UpdateParams) => {
      return TicketInstance.ticketsTicketIdPut({
        ticketId: params.ticketId,
        ticketsTicketIdDeleteRequest: params.ticketsTicketIdDeleteRequest
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      }
    }
  );

  return {
    startMutate: startMutation.mutate,
    pendingMutate: pendingMutation.mutate,
    resumeMutate: resumeMutation.mutate,
    startProcessMutate: startProcessMutation.mutate,
    finishProcessMutate: finishProcessMutation.mutate,
    finishMutate: finishMutation.mutate,
    restartMutate: restartMutation.mutate,
    saveParkingLocationMutate: saveParkingLocationMutation.mutate,
    updateMutate: updateMutation.mutate,
  }
};

export default useRepairMutation;
