import { ReactComponent as Close_Icon } from "images/svg/close_icon.svg";
import { ReactComponent as Benz_Icon } from "images/svg/light_logo.svg";
import { ReactComponent as Logout_Icon } from "images/svg/logout_icon.svg";
import { ReactComponent as Next_Icon } from "images/svg/next_icon.svg";
import { ReactComponent as Prev_Icon } from "images/svg/prev_icon.svg";

export const icons = {
  Benz_Icon,
  Close_Icon,
  Logout_Icon,
  Prev_Icon,
  Next_Icon
};
