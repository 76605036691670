import styled from "@emotion/styled";
import { Box, MediaQuery, Table, Text } from "@mantine/core";
import dayjs from "dayjs";
import useParkingHistoryQuery from "../api/useParkingHistoryQuery";


interface ParkingHistoryProps {
    ticketId: number;
}

export const ParkingHistory = ({ ticketId }: ParkingHistoryProps) => {
    const { data } = useParkingHistoryQuery(ticketId);

    return (
        <>
            <MediaQuery styles={{ display: "none" }} smallerThan="sm">
                <Box w="40rem">
                    <Table withBorder withColumnBorders>
                        <thead>
                            <tr>
                                <Th width={40}>주차위치</Th>
                                <Th width={60}>주차된 시간</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <tr key={item.createdAt}>
                                        <td>
                                            <Text fw={!index ? 700 : 400} c={!index ? 'blue' : ''}>
                                                {item.description}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text fw={!index ? 700 : 400} c={!index ? 'blue' : ''}>
                                                {dayjs(item.createdAt).format("YYYY-MM-DD A hh:mm")}
                                            </Text>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                data && data.length === 0 && (
                                    <tr>
                                        <td colSpan={2}><Text ta="center">주차 내역이 없습니다.</Text></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Box>
            </MediaQuery>
            <MediaQuery styles={{ display: "none" }} largerThan="sm">
                <Box w="20rem">
                    <Table withBorder withColumnBorders>
                        <thead>
                            <tr>
                                <Th width={40}>주차위치</Th>
                                <Th width={60}>주차된 시간</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <tr key={item.createdAt}>
                                        <td>
                                            <Text fw={!index ? 700 : 400} c={!index ? 'blue' : ''}>
                                                {item.description}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text fw={!index ? 700 : 400} c={!index ? 'blue' : ''}>
                                                {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                                            </Text>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                data && data.length === 0 && (
                                    <tr>
                                        <td colSpan={2}><Text ta="center">주차 내역이 없습니다.</Text></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Box>
            </MediaQuery>
        </>
    )
}


const Th = styled.th<{ width?: number; }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;
