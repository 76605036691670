import { Global, css } from "@emotion/react";

function GlobalStyle() {

  const globalCSS = css`
    :root {
      --zIndex-0st: 11000;
      --zIndex-1st: 10000;
      --zIndex-2st: 9000;
      --zIndex-3st: 8000;

      --header-height: 2.5rem;
      --option-btn-right: 1rem;
    }

    @font-face {
      font-family: 'MBK CorpoS';
      src: url(../fonts/MBKCorporateS.eot);
      src: url(../fonts/MBKCorporateS.eot?#iefix) format('embedded-opentype'),
          url(../fonts/MBKCorporateS.woff) format('woff'),
          url(../fonts/MBKCorporateS.woff2) format('woff2'),
          url(../fonts/MBKCorporateS.ttf) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      word-break: keep-all;
      font-family: MBK CorpoS !important;
    }
    html {
      font-size: 100%;
    }
    body {
      font-size: 1.6rem;
      font-family: MBK CorpoS !important;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      background-color: #f1f3f4;
    }
    a {
      text-decoration: none;
      color: black;
    }
    ul {
      list-style-type: none;
    }
    img {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    button {
      border: none;
      background-color: transparent;
      border-radius: 1rem;
      cursor: pointer;
      &:active,
      &:focus {
        outline: none;
      }
    }
    input {
      outline: none;
      &:focus::placeholder {
        color: transparent;
      }
    }
  `;

  return <Global styles={[globalCSS]} />;
}

export default GlobalStyle;
