import { TicketApiTicketsTicketIdParkingLocationHistoryGetRequest, TicketsTicketIdParkingLocationHistoryGet200ResponseInner } from "@sizlcorp/mbk-api-document/dist/models";
import { AxiosResponse } from "axios";
import { TicketInstance } from "instance/axios";
import { useQuery } from "react-query";

// useQuery에서 사용할 UniqueKey를 상수로 선언하고 export로 외부에 노출합니다.
// 상수로 UniqueKey를 관리할 경우 다른 Component (or Custom Hook)에서 쉽게 참조가 가능합니다.
type TicketParkingHistoryGetParams = TicketApiTicketsTicketIdParkingLocationHistoryGetRequest;

export const QUERY_KEY = "/parking-history";

const fetchParkingHistoryGet = (params: TicketParkingHistoryGetParams) => {
    return TicketInstance.ticketsTicketIdParkingLocationHistoryGet({ ticketId: params.ticketId });
}

const useParkingHistoryQuery = (ticketId: number) => {
    return useQuery(
        [QUERY_KEY, ticketId],
        () => fetchParkingHistoryGet({ ticketId }),
        {
            select: (data: AxiosResponse<TicketsTicketIdParkingLocationHistoryGet200ResponseInner[], any>) =>
                data.data as TicketsTicketIdParkingLocationHistoryGet200ResponseInner[]
        }
    );
};

export default useParkingHistoryQuery;
