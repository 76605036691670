import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Flex, Input, MediaQuery, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UserInfo, userInfoAtom } from "recoil/layoutAtom";
import { deviceSize } from "style/theme";
import useGetAuthToken from "./hook/useGetAuthToken";

function Auth() {
  const firstInputRef = useRef<HTMLInputElement | null>(null);
  const formData = useRef<Map<string, any>>(new Map());
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const loginCallback = ({ centerName, managerName, workBayName }: Partial<UserInfo>) => {
    setUserInfo({
      workBayName: workBayName ?? "",
      centerName: centerName ?? "",
      managerName: managerName ?? "",
    });
  };
  const onSignIn = async () => {
    const emailValue = formData.current.get("id");
    const passwordValue = formData.current.get("password");
    const validate = emailValue && passwordValue;

    if (validate) {
      setLoginInform({ identifier: emailValue, password: passwordValue });
    } else {
      alert("please enter email, password");
    }
  };
  const [loginInform, setLoginInform] = useState<{
    identifier: string;
    password: string;
  }>();

  const { isLoginLoading, loginRefetch } = useGetAuthToken(
    loginInform as { identifier: string; password: string },
    loginCallback
  );

  useEffect(() => {
    if (loginInform?.identifier && loginInform?.password) {
      loginRefetch();
    }
  }, [loginInform, loginRefetch]);

  return (
    <AuthContainer>
      {isLoginLoading ? (
        <LoadingSpinner />
      ) : (
        <Flex align="center" justify="center">
          <LoginForm onSubmit={(e) => e.preventDefault()}>
            <MediaQuery styles={{ display: "none" }} smallerThan="sm">
              <Flex w="100%" direction="column" gap="3rem">
                <Flex w="100%" align="center" justify="center" gap="3rem">
                  <Text fz="3rem" color="white" w="30%">아이디</Text>
                  <Input
                    w="70%"
                    size="xl"
                    ref={firstInputRef}
                    type="text"
                    autoFocus
                    onChange={(e) => {
                      formData.current.set("id", e.target.value);
                    }}
                  />
                </Flex>
                <Flex w="100%" align="center" justify="center" gap="3rem">
                  <Text fz="3rem" color="white" w="30%">패스워드</Text>
                  <Input
                    w="70%"
                    size="xl"
                    type="password"
                    onChange={(e) => {
                      formData.current.set("password", e.target.value);
                    }}
                  />
                </Flex>
              </Flex>
            </MediaQuery>
            <MediaQuery styles={{ display: "none" }} largerThan="sm">
              <Flex w="100%" direction="column" gap="3rem">
                <Flex w="100%" align="flex-start" justify="center" direction="column">
                  <Text fz="2rem" color="white">아이디</Text>
                  <Input
                    w="100%"
                    size="xl"
                    ref={firstInputRef}
                    type="text"
                    autoFocus
                    onChange={(e) => {
                      formData.current.set("id", e.target.value);
                    }}
                  />
                </Flex>
                <Flex w="100%" align="flex-start" justify="center" direction="column">
                  <Text fz="2rem" color="white">패스워드</Text>
                  <Input
                    w="100%"
                    size="xl"
                    type="password"
                    onChange={(e) => {
                      formData.current.set("password", e.target.value);
                    }}
                  />
                </Flex>
              </Flex>
            </MediaQuery>
            <Button type="submit" onClick={onSignIn} size="xl" w="100%" color="indigo">로그인</Button>
          </LoginForm>
        </Flex>
      )}
    </AuthContainer>
  );
}

const AuthContainer = styled.section`
  border-radius: 4rem;
  padding: 3rem;
  background: black;
  width: 100%;
`

const DarkBtn = styled.button`
  width: 10rem;
  height: 100%;

  color: white;
  font-size: 3.2rem;
  font-family: MBK CorpoS;
  font-weight: 400;
  letter-spacing: 0.032rem;
  word-wrap: break-word;
  border: 0.4rem solid transparent;
  border-image: linear-gradient(-10deg, #ffffff, #6c6a6a);
  border-image-slice: 1;

  @media screen and (min-width: ${deviceSize.tablet}) {
    width: 20rem;
  }
`;


const LoginForm = styled.form`
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
  /* background-color: blue; */
`;

const InputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6.4rem;
  gap: 3rem;
`;

const InputLabel = styled.label`
  width: 30%;
  color: white;
  font-size: 3rem;
  font-family: MBK CorpoA;
  font-weight: 400;
  letter-spacing: 0.336rem;
  word-wrap: break-word;
  text-align: right;
`;

// const Input = styled.input`
//   width: 100%;
//   height: 100%;
//   border: none;
//   background: ${colors.grayGradient_100};
//   padding: 1.2rem;
//   font-size: 2.5rem;
//   color: white;
// `;

const LoginBtn = styled(DarkBtn)`
  width: 15rem;
  font-size: 2.2rem;
  padding: 2.2rem;
  background-color: black;
  transform: translateX(30%);

  @media screen and (min-width: ${deviceSize.tablet}) {
    width: 20rem;
  }
`;

// 회전 애니메이션을 정의
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 스타일드 컴포넌트 생성
const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 0.8s linear infinite;
  margin: 20px auto;
`;

export default Auth;
