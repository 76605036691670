import { AuthSignupPost201Response, PendingReasonResponse } from "@sizlcorp/mbk-api-document/dist/models";
import { AxiosResponse } from "axios";
import BaseInstance, { MasterInstance } from "instance/axios";
import { useQuery } from "react-query";

export type SelectOption = {
    value: string;
    label: string;
};

export const PROCESS_QUERY_KEY = "/process";
export const PENDING_REASON_QUERY_KEY = "/pendingReason";
export const USER_QUERY_KEY = "/user";

const fetchProcessesGet = (siteId: number | undefined) => {
    return MasterInstance.processesFindPost({
        query: [JSON.stringify({
            site_id: siteId,
        })]
    });
}

// 상세 정비 조회
export const useProcessQuery = (siteId: number | undefined) => {
    return useQuery(
        [PROCESS_QUERY_KEY],
        () => fetchProcessesGet(siteId),
        {
            select: (data: AxiosResponse<object[], any>) =>
                data.data as object[],
            enabled: siteId !== undefined
        }
    );
};

const fetchPendingReasonsGet = (siteId: number | undefined, pendingType: string | undefined) => {
    return MasterInstance.pendingReasonsFindPost({
        query: [JSON.stringify({
            "$and": {
                "pendingType": pendingType,
                "$or": [
                    {
                        "siteId": {
                            "$eq": siteId
                        }
                    },
                    {
                        "siteId": {
                            "$is": null
                        }
                    }
                ]
            }
        })]
    });
}

// 지연 사유 조회
export const usePendingReasonQuery = (siteId: number | undefined, pendingType: string | undefined) => {
    return useQuery(
        [PENDING_REASON_QUERY_KEY],
        () => fetchPendingReasonsGet(siteId, pendingType),
        {
            select: (data: AxiosResponse<PendingReasonResponse[], any>) =>
                data.data as PendingReasonResponse[],
            enabled: siteId !== undefined && pendingType !== undefined
        }
    );
}


const fetchUsersGet = (siteId: number) => {
    return MasterInstance.usersFindPost({
        query: [JSON.stringify({
            "$and": {
                "$or": [
                    {
                        "siteId": {
                            "$eq": siteId
                        }
                    },
                    {
                        "siteId": {
                            "$is": null
                        }
                    }
                ]
            }
        })]
    });
}

export const useUserQuery = (siteId: number) => {
    return useQuery(
        [USER_QUERY_KEY],
        () => fetchUsersGet(siteId),
        {
            select: (data: AxiosResponse<AuthSignupPost201Response[]>): SelectOption[] =>
                data.data.map((user) => {
                    return {
                        value: user.id.toString(),
                        label: user.name as string,
                    };
                }) ?? [],
            enabled: siteId !== undefined
        }
    );
}

const fetchWhoamiGet = () => {
    const token = localStorage.getItem('authToken');
    return BaseInstance.whoamiGet({ headers: { Authorization: `Bearer ${token}` } });
};

export const useWhoamiQuery = () => {
    const token = localStorage.getItem('authToken');
    return useQuery(
        "whoami",
        () => fetchWhoamiGet(),
        {
            enabled: !!token,
            select: (data: AxiosResponse<AuthSignupPost201Response, any>) =>
                data.data as AuthSignupPost201Response
        }
    )
}
