import { useEffect } from "react";
import { pdfjs } from "react-pdf";
import Router from "router";
import setupDayjs from "utils/setupDay";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function App() {

  useEffect(() => {
    setupDayjs();  // dayjs 설정 적용
  }, []);

  return <Router />;
}

export default App;
