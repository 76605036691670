import styled from "@emotion/styled";
import Header from "components/Layout/Header";
import ProtectedRoute from "components/Router/ProtectedRoute";
import Auth from "pages/auth";
import QR from "pages/qr";
import Repair from "pages/repair";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useCheckAuth } from "utils/checkAuth";

function Index() {
  useLocation();
  const auth = useCheckAuth();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath="/qr">
              <Auth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/qr"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/">
              <QR />
            </ProtectedRoute>
          }
        />
        <Route
          path="/repair/:ticketId"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/qr"
            >
              <Repair />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

const Layout = () => {
  return (
    <Main>
      <Header />
      <Outlet />
    </Main>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  position:relative; 
  width:100%; 
  max-width:1024px; 
  margin:0 auto; 
  padding:2rem 1rem;
`;


export default Index;
