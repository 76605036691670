
export const StepStatus = {
    "RECEIPTED": "접수됨",
    "ACCEPT_PENDING": "접수 완료 지연중",

    "WAITING": "정비 대기중",
    "PENDING": "정비 전 지연중",
    "STAGE_IN_PROGRESS": "정비단계 진행중",
    "IN_PROGRESS_PENDING": "정비단계 진행중 지연중",
    "STAGE_WAITING": "정비단계 대기중",
    "WAITING_PENDING": "정비단계 시작 전 지연중",
    "PROCESSING": "상세 정비중",
    "PROCESS_PENDING": "상세 정비 지연중",
    "DONE": "정비 완료됨",

    "DONE_CONFIRMED": "정비 완료 확인됨",
    "DELIVERY_PENDING": "출고 지연중",
    "CLOSED": "정비 마감됨",
    "DELIVERED": "출고 완료됨",

    "TEMPORARY_STAGE_IN_PROGRESS": "임시 정비단계 진행중"
}

export const StepStatusData = {
    "WAITING": "WAITING",
    "TEMPORARY_STAGE_IN_PROGRESS": "TEMPORARY_STAGE_IN_PROGRESS",
    "PENDING": "PENDING",
    "STAGE_IN_PROGRESS": "STAGE_IN_PROGRESS",
    "IN_PROGRESS_PENDING": "IN_PROGRESS_PENDING",
    "STAGE_WAITING": "STAGE_WAITING",
    "WAITING_PENDING": "WAITING_PENDING",
    "PROCESSING": "PROCESSING",
    "PROCESS_PENDING": "PROCESS_PENDING",
    "DONE": "DONE"
}

export type StepPendingStatus = {
    "WAITING": "PENDING",
    "STAGE_IN_PROGRESS": "IN_PROGRESS_PENDING",
    "STAGE_WAITING": "WAITING_PENDING",
    "PROCESSING": "PROCESS_PENDING"
}

// StepStatusDataPending 정의
export const StepStatusDataPending = {
    "RECEIPTED": "ACCEPT_PENDING",
    "WAITING": "PENDING",
    "STAGE_IN_PROGRESS": "PENDING",
    "STAGE_WAITING": "PENDING",
    "PROCESSING": "PENDING",
    "DONE_CONFIRMED": "DELIVERY_PENDING",
} as const;

export type StepStatusDataPendingKeys = keyof typeof StepStatusDataPending;