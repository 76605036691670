import { authEnum } from "config/auth";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { authTokenAtom } from "recoil/authAtom";
import { initialUserInfo, userInfoAtom } from "recoil/layoutAtom";

export function useLogout() {
  const navigate = useNavigate();
  const setAuthTokenAtom = useSetRecoilState(authTokenAtom);
  const setFooterInfoAtom = useSetRecoilState(userInfoAtom);

  return () => {
    setAuthTokenAtom("");
    setFooterInfoAtom(initialUserInfo);
    localStorage?.removeItem(authEnum.authToken);
    localStorage?.removeItem('siteId');

    navigate("/", { replace: true });
  };
}
