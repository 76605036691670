import styled from "@emotion/styled";
import { ActionIcon, Flex, MediaQuery, Text } from "@mantine/core";
import { IconLogout } from '@tabler/icons-react';
import axios from "axios";
import { useLogout } from "hook/useLogout";
import { BASE_URL } from "instance/axios";
import { icons } from "modules/icons";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfoAtom } from "recoil/layoutAtom";
import { useCheckAuth } from "utils/checkAuth";

type USER_ROLES = "ADMIN" | "CENTER_ADMIN" | "TECHNICIAN" | "ADVISER";

export const ROLE_MAP: {
  [key in USER_ROLES]: string;
} = {
  "ADMIN": "개발자",
  "CENTER_ADMIN": "관리자",
  "TECHNICIAN": "테크니션",
  "ADVISER": "어드바이저",
}

function Index() {
  const isLogin = useCheckAuth();
  const navigate = useNavigate();
  const logout = useLogout();

  const setUserInfo = useSetRecoilState(userInfoAtom);

  const loadUserData = useCallback(async () => {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      const result = await axios.get(`${BASE_URL}/whoami`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })

      setUserInfo({
        centerName: result.data.site?.name,
        managerName: `${ROLE_MAP[result.data.role as USER_ROLES]} ${result.data.name}`,
        workBayName: result.data.workbay?.name,
      });
    }
  }, [setUserInfo])

  useEffect(() => {
    loadUserData()
  }, [loadUserData])

  const userInfo = useRecoilValue(userInfoAtom);

  return (
    <Nav>
      <ActionIcon variant="transparent" w="12.5rem" onClick={() => { navigate('/qr') }}>
        <icons.Benz_Icon alt="로고" />
      </ActionIcon>
      {
        isLogin && (
          <>
            <MediaQuery styles={{ display: "none" }} smallerThan="sm">
              <Flex align="center" justify="flex-end" w="25rem" h="100%" gap="lg">
                <Flex gap="xl" w="15rem">
                  <Text color="white" fz="md">{userInfo.centerName}</Text>
                  <Text color="white" fz="md">{userInfo.managerName}</Text>
                  <Text color="white" fz="md">{userInfo.workBayName}</Text>
                </Flex>
                <ActionIcon variant="transparent" onClick={logout} w="5rem">
                  <Flex justify="space-between" gap="md">
                    <IconLogout />
                    <Text color="white" fz="md">로그아웃</Text>
                  </Flex>
                </ActionIcon>
              </Flex>
            </MediaQuery>
            <MediaQuery styles={{ display: "none" }} largerThan="sm">
              <ActionIcon variant="transparent" onClick={logout} >
                <IconLogout />
              </ActionIcon>
            </MediaQuery>
          </>
        )
      }
    </Nav>
  );
}


const Nav = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 7.5rem;
  margin-bottom: 2rem;
  background: #1a1c1e;
  padding: 2rem 2.5rem;
  border-radius: 7.5rem;
`;


export default Index;
